<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addEmoji">
          <md-card-content>
            <div class="md-layout md-gutter">
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
                name="emoji"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Эмоция</label>
                  <md-file
                    v-model="file"
                    accept=".svg, .apng, .gif"
                    @input="handleChange"
                  />
                  <span class="md-helper-text">
                    Файл должен быть форматов GIF, APNG, SVG и весить от 2 до
                    200 килобайт
                  </span>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="buttons-wrap justify-end">
              <md-button
                class="md-raised md-danger"
                @click="$router.push('/fast-messages/emoji/list/')"
              >
                Отменить
              </md-button>
              <md-button class="md-raised md-success" :disabled="isValid">
                Создать
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      isValid: false,
    };
  },
  methods: {
    handleChange(e) {
      if (e.target) {
        const file = e.target.files[0];
        if (file && (file.size < 2 * 1024 || file.size > 200 * 1024)) {
          this.$refs.addEmoji.setErrors({
            emoji: "Ошибка. Проверьте загружаемый файл",
          });
          this.isValid = true;
        } else {
          this.$refs.addEmoji.setErrors({
            emoji: null,
          });
          this.isValid = false;
        }
      }
    },
  },
};
</script>

<style>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}
</style>
